import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import CtaBar from '../components/cta-bar'
import Testimonials from '../components/testimonials'
import Clients from '../components/clients'

import externalLink from '../assets/images/external-link.png'
import impact from '../assets/images/impact.png'
import compliance from '../assets/images/compliance.png'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'


import Button from 'react-bootstrap/Button'



class CareerPage extends React.Component {
  render() {
    const complianceDocs = get(this, 'props.data.allContentfulComplianceDocs.edges')
    const testimonials = get(this, 'props.data.allContentfulTestimonial.edges')
    const location = this.props.location
    const body = get(this, 'props.data.allContentfulPageContent.edges[0].node')
    const jobLink = get(this, 'props.data.allContentfulJobListingLink.edges[0].node.link')

    return (
      <Layout location={this.props.location}>
        <div className="inner-page-careers">
        <Container>
          <Breadcrumb location={location} crumbLabel="Careers" />
        </Container>
        <Row className="inner-page-banner careers-banner">
<Col>
<h2>CAREERS</h2>
  <h1>V-Tech + You</h1>
  <a href={jobLink} target="_new"> <Button className="cta-button-banner">JOB LISTINGS</Button></a>
</Col>
</Row>
          <Container>
            <Row className="space">
                <Col md="5">
                    <img src={impact}/>
                </Col>
              <Col className="my-auto" md="7 ">
                <h2 className="green-headline">{body.title}</h2>
                <p>{body.body.body}</p>
              </Col>
            </Row>
          </Container>
          <CtaBar/>
          <Container>
          <Row className="space">
              <Col md="7">
                  <h2 className="green-headline">Compliance</h2>
                  {complianceDocs.map((doc) => {
                      return(
                      <React.Fragment>
                      <a className="doc-link" target="_blank" href={doc.node.file.file.url}>
                      <img className="doc-link-icon" src={externalLink}/>
                      <h3 className="doc-link-text">{doc.node.name}</h3>
                      </a>
                      </React.Fragment>
                      )
                  })}

              </Col>
              <Col md="5" className="my-auto">
              <img src={compliance}/>
              </Col>
          </Row>
          </Container>
         <Testimonials testimonials={testimonials}/>
          <Clients/>
        </div>
      </Layout>
    )
  }
}

export default CareerPage

export const pageQuery = graphql`
  query CareerQuery {
    allContentfulPageContent(filter: {node_locale: {eq: "en-US"}, id: {eq: "f7d604c6-1a60-5651-851b-621e25b7c4df"}}) {
      edges {
        node {
          title
          body {
            body
          }
          id
        }
      }
    }
    allContentfulComplianceDocs(filter: {node_locale: {eq: "en-US"}}) {
        edges {
          node {
            name
            file {
              file {
                url
              }
            }
          }
        }
      }
      allContentfulTestimonial(filter: {node_locale: {eq: "en-US"}}) {
        edges {
          node {
            name
            title
            quote
            image {
              file {
                url
              }
            }
          }
        }
      }
      allContentfulJobListingLink {
        edges {
          node {
            link
          }
        }
      }
  }
`
